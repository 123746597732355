/**
 * api管理
 */
  
  
import request from './../utils/request'
export default {
  // 注册
  register(data) {
    return request({
      url: 'login/register',
      method: 'POST',
      data,
    })
  },
  // 获取验证码
  getCode(data) {
    return request({
      url: 'common/sendSms',
      method: 'POST',
      data
    })
  },
  // 用户登录
  login(data) {
    return request({
      url: 'login/toLogin',
      method: 'POST',
      data,
    })
  },
  // 验证码登录
  logincode(data) {
    return request({
      url: 'login/codeLogin',
      method: 'POST',
      data,
    })
  },

  // 修改密码
  setPasswd(data) {
    return request({
      url: 'users/editPassword',
      method: 'POST',
      data
    })
  },

  // 获取用户信息
  getUserInfo(data) {
    return request({
      url: 'users/userInfo',
      method: 'POST',
      data
    })
  },
  // 获取导航分类
  getNavCate(data) {
    // return request({
    //   url: 'index/getNav',
    //   method: 'POST',
    //   data
    // })
	return request({
	  url: 'index/getNavNew',
	  method: 'POST',
	  data
	})
  },
  // 获取导航详情
  getNavCateDetail(data) {
    return request({
      url: 'index/getNavList',
      method: 'POST',
      data
    })
  },
  // 导航详情中的列表详情
  getNavCateListDetail(data) {
    return request({
      url: 'nanny/detail',
      method: 'POST',
      data
    })
  },
  // 首页接口
  getshouyelist(data){
	  return request({
	    url: 'index/getHomeNew',
	    method: 'POST',
	    data
	  })
  },
  // 获取首页广告位
  getguanggao(data){
	  return request({
	    url: 'common/getAdvert',
	    method: 'POST',
	    data
	  })
  },
  // 获取入驻表单
  getruzhuform(data){
	  return request({
	    url: 'common/get_fields',
	    method: 'POST',
	    data
	  })
  },
  // 提交表单
  submitform(data){
  	  return request({
  	    url: 'nanny/save',
  	    method: 'POST',
  	    data
  	  })
  },
  // 提交表单2
  submitforma(data){
  	  return request({
  	    url: 'demand/save',
  	    method: 'POST',
  	    data
  	  })
  },
  
  
  // 首页广告位
  getDefaultImg(data) {
    return request({
      url: 'common/getDefaultImg',
      method: 'POST',
      data
    })
  },
  // 首页广告位
  getHomeAdList(data) {
    return request({
      url: 'index/getHome',
      method: 'POST',
      data
    })
  },
  // type=2 管道疏通列表详情
  getInviteServiceDetail(data) {
    return request({
      url: 'index/getInviteServiceDetail',
      method: 'POST',
      data
    })
  },
  // 获取订单列表
  getOrderList(data) {
    return request({
      url: 'demand/lists',
      method: 'POST',
      data
    })
  },
  // 订单详情
  getOrderDetail(data) {
    return request({
      url: 'demand/info',
      method: 'POST',
      data
    })
  },
  // 订单评论
  getOrderdemandEvaluate(data) {
    return request({
      url: 'demand/evaluate',
      method: 'POST',
      data
    })
  },
  // 取消订单服务
  getOrderCancel(data) {
    return request({
      url: 'demand/cancel',
      method: 'POST',
      data
    })
  },
  // 删除订单
  getOrderDel(data) {
    return request({
      url: 'demand/del',
      method: 'POST',
      data
    })
  },
  // 发布评论
  addComment(data) {
    return request({
      url: 'forum/add_comment',
      method: 'POST',
      data
    })
  },
  // 获取地区A-Z
  getAreaInfo(data) {
    return request({
      url: 'common/getAreaList',
      method: 'POST',
      data
    })
  },
  // 获取地区
  getAreaInfos(data) {
    return request({
      url: 'common/get_area',
      method: 'POST',
      data
    })
  },
  // 获取充值积分列表
  getRechargeList(data) {
    return request({
      url: 'recharge/recharge_list',
      method: 'POST',
      data
    })
  },
  // 充值记录
  getRechargeLog(data) {
    return request({
      url: 'recharge/rechargeLog',
      method: 'POST',
      data
    })
  },
  // 获取新闻列表
  getNewsList(data) {
    return request({
      url: 'news/lists',
      method: 'POST',
      data
    })
  },
  // 获取新闻分类
  getNewsCate(data) {
    return request({
      url: 'news/getCategory',
      method: 'POST',
      data
    })
  },
  // 获取新闻详情
  getNewsDetail(data) {
    return request({
      url: '/news/info',
      method: 'POST',
      data
    })
  },
  // 公告列表
  getNoticeList(data) {
    return request({
      url: 'notice/lists',
      method: 'POST',
      data
    })
  },
  // 公告详情
  getNoticeDetail(data) {
    return request({
      url: 'notice/detail',
      method: 'POST',
      data
    })
  },
  // 图片上传
  imageUpload(data) {
    return request({
      url: 'common/doupload',
      method: 'POST',
      data
    })
  },
  // 更换用户头像
  changeAvatar(data) {
    return request({
      url: 'users/changeAvatar',
      method: 'POST',
      data
    })
  },
  // 获取发布列表
  getReleaseList(data) {
    return request({
      url: 'second/lists',
      method: 'POST',
      data
    })
  },
  // 获取发布详情
  getReleaseInfo(data) {
    return request({
      url: 'second/detail',
      method: 'POST',
      data
    })
  },
  // 下架发布
  releaseDown(data) {
    return request({
      url: 'second/take_down',
      method: 'POST',
      data
    })
  },
  // 置顶发布
  releaseUp(data) {
    return request({
      url: 'second/take_top',
      method: 'POST',
      data
    })
  },
  // 上架
  shangjia(data) {
    return request({
      url: 'second/take_up',
      method: 'POST',
      data
    })
  },
  // 保存发布
  saveRelease(data) {
    return request({
      url: 'second/save',
      method: 'POST',
      data
    })
  },
  // 发布编辑视图
  getReleaseEdit(data) {
    return request({
      url: 'second/edit',
      method: 'POST',
      data
    })
  },
  // 保存编辑发布
  releaseEdit(data) {
    return request({
      url: 'second/save_edit',
      method: 'POST',
      data
    })
  },
  // 发布类型
  getReleaseType(data) {
    return request({
      url: 'common/getFaCategory',
      method: 'POST',
      data
    })
  },
  // 添加入驻
  addSettle(data) {
    return request({
      url: 'nanny/save',
      method: 'POST',
      data
    })
  },
  // 入驻分类
  getSettleCate(data) {
    return request({
      url: 'nanny/nav_lists',
      method: 'POST',
      data
    })
  },
  // 获取帮助反馈列表 
  feedbackList(data) {
    return request({
      url: 'index/get_cate',
      method: 'POST',
      data
    })
  },
  // 获取帮助反馈详情
  feedbackInfo(data) {
    return request({
      url: 'index/get_cate_detail',
      method: 'POST',
      data
    })
  },
  // 检测是否设置安全密码
  checkSetPasswd(data) {
    return request({
      url: 'users/check_set_passmd',
      method: 'POST',
      data
    })
  },
  // 获取字段
  getColumns(data) {
    return request({
      url: 'common/get_fields',
      method: 'POST',
      data
    })
  },
  // 保存需求
  saveNeed(data) {
    return request({
      url: 'demand/save',
      method: 'POST',
      data
    })
  },
  // 获取配置信息
  getSettingInfo(data) {
    return request({
      url: 'common/getConfig',
      method: 'POST',
      data
    })
  },
  // 获取地区详情
  areadetail(data) {
    return request({
      url: 'common/getAreaDetail',
      method: 'POST',
      data
    })
  },
  // 修改用户昵称
  editNickname(data) {
    return request({
      url: 'users/editnickname',
      method: 'POST',
      data
    })
  },
  // 校验是否入驻
  getCheckApply(data) {
    return request({
      url: '/nanny/check',
      method: 'POST',
      data
    })
  },
  // 获取美食团购类型
  getfoodtypes(data) {
    return request({
      url: 'common/get_code_fields',
      method: 'POST',
      data
    })
  },
  // 支付成为会员
  member(data) {
    return request({
      url: 'users/member',
      method: 'POST',
      data
    })
  },
  // 充值
  chongz(data) {
    return request({
      url: 'recharge/do_recharge_new',
      method: 'POST',
      data
    })
  },
  // 支付方式
  members(data) {
    return request({
      url: 'recharge/getpay_list',
      method: 'POST',
      data
    })
  },
  // 分享赠送积分
  shareitem(data) {
    return request({
      url: 'share/add',
      method: 'POST',
      data
    })
  },
  // 删除评论
  shanchus(data) {
    return request({
      url: 'forum/del_comment',
      method: 'POST',
      data
    })
  },
  // 获取广告位(竖直)
  shuadv(data) {
    return request({
      url: 'common/getAdvertLr',
      method: 'POST',
      data
    })
  },
  // 检测手机号是否注册
  checkzhuc(data) {
    return request({
      url: 'login/checkphone',
      method: 'POST',
      data
    })
  },
  // 忘记密码
  forgetmima(data) {
    return request({
      url: 'login/setPass',
      method: 'POST',
      data
    })
  }
}