import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import storage from '../utils/storage'

const routes = [
    {
        name: 'home',
        path: '/',
        meta: {
            title: '首页'
        },
        component: () => import('./../views/Home.vue')
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('./../views/auth/login.vue')
    },
    {
        name: 'forget',
        path: '/forget',
        component: () => import('./../views/auth/forget.vue')
    },
    {
        name: 'Qrcode',
        path: '/qrcode',
        component: () => import('./../views/auth/qrcode.vue')
    },
    {
        name: 'register',
        path: '/register',
        component: () => import('./../views/auth/register.vue')
    },
    // 宝妈互助
    {
        name: 'mom',
        path: '/mom',
        component: () => import('./../views/nav/mom.vue')
    },
    {
        name: 'momInfo',
        path: '/momInfo/:id',
        component: () => import('./../views/nav/momInfo.vue')
    },
	// 广告详情
	{
	    name: 'guanggaodetail',
	    path: '/guanggaodetail',
	    component: () => import('./../views/nav/guanggaodetail.vue')
	},
    // 资讯专区
    {
        name: 'news',
        path: '/news',
        component: () => import('./../views/nav/news.vue')
    },
    {
        name: 'newsDetails',
        path: '/news/:id',
        component: () => import('./../views/nav/newsDetail.vue')
    },
    // 信息专区
    {
        name: 'notice',
        path: '/notice',
        component: () => import('./../views/nav/notice.vue')
    },
    {
        name: 'noticeInfo',
        path: '/notice/:id',
        component: () => import('./../views/nav/noticeInfo.vue')
    },
    {
        name: 'news',
        path: '/news',
        component: () => import('./../views/nav/news.vue')
    },
    // 公告
    {
        name: 'message',
        path: '/message',
        component: () => import('./../views/nav/message.vue')
    },
    {
        name: 'messageInfo',
        path: '/message/:id',
        component: () => import('./../views/nav/messageInfo.vue')
    },

    {
        name: 'sitter',
        path: '/sitter/:id',
        component: () => import('./../views/nav/sitter.vue'),
    },
    {
        name: 'sitterInfo',
        path: '/sitterInfo/:id',
        component: () => import('./../views/nav/sitter-info.vue')
    },
    {
        name: 'card',
        path: '/card',
        component: () => import('./../views/user/card.vue')
    },
    {
        name: 'maintainServer',
        path: '/maintainServer',
        component: () => import('./../views/nav/maintainServer.vue')
    },
    // 房屋销售
    {
        name: 'houseSale',
        path: '/houseSale/:id',
        component: () => import('./../views/nav/houseSale.vue')
    },
    {
        name: 'houseSaleInfo',
        path: '/houseSaleInfo/:id',
        component: () => import('./../views/nav/houseSaleInfo.vue')
    },
    // 二手产品
    {
        name: 'second',
        path: '/second/:id',
        component: () => import('./../views/nav/second.vue')
    },
    {
        name: 'secondInfo',
        path: '/secondInfo/:id',
        component: () => import('./../views/nav/secondInfo.vue')
    },
    {
        name: 'serve',
        path: '/server',
        component: () => import('./../views/nav/server.vue')
    },
    {
        name: 'apply',
        path: '/apply',
        component: () => import('./../views/nav/apply.vue')
    },
    // 个人中心
    {
        name: 'user',
        path: '/user',
        component: () => import('./../views/user/user.vue'),
        children: [
            {
                path: 'info',
                name: 'info',
                component: () => import('./../views/user/info.vue'),
            },
            {
                path: 'order',
                name: 'order',
                component: () => import('./../views/user/order.vue'),
            },
            {
                path: 'release',
                name: 'release',
                component: () => import('./../views/user/release.vue')
            },
            {
                path: 'server',
                name: 'server',
                component: () => import('./../views/user/server.vue')
            },
            {
                path: 'account',
                name: 'account',
                component: () => import('./../views/user/account.vue')
            },
            {
                path: 'other',
                name: 'other',
                component: () => import('./../views/user/other.vue')
            }
        ]
    },
    // 充值
    {
        name: 'recharge',
        path: '/user/account/recharge',
        component: () => import('./../views/user/recharge.vue'),
    },
	// 会员升级
	{
	    name: 'shengji',
	    path: '/user/account/shengji',
	    component: () => import('./../views/user/shengji.vue'),
	},
    // 充值记录(明细)
    {
        name: 'rechargeLog',
        path: '/user/account/rechargeLog',
        component: () => import('./../views/user/rechargeLog.vue'),
    },
    // 订单详情
    {
        name: 'orderDetail',
        path: '/user/order/:id',
        component: () => import('./../views/user/orderDetail.vue'),
    },

    // 订单评论
    {
        name: 'orderEvaluate',
        path: '/user/orderEvaluate/:id',
        component: () => import('./../views/user/orderEvaluate.vue'),
    },
    {
        name: 'otherInfo',
        path: '/user/other/:id',
        component: () => import('./../views/user/otherInfo.vue')
    },
    // 切换城市
    {
        name: 'checkCity',
        path: '/selectCity',
        component: () => import('./../views/selectCity.vue'),
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router