/**
 * Vuex状态管理
 */
import { createStore } from 'vuex'
import mutations from './mutations'
import storage from './../utils/storage'

const state = {
    loginToken: '',
    userInfo: "" || storage.getItem("userInfo"), // 获取用户信息
    currentNavIndex: 0,
    regionData: '' || storage.getItem('regionData'), // 地区id
    avatar: '', // 头像
    safePass: '' || storage.getItem('safePass'), // 安全密码检测
    platformInfo: '' || storage.getItem('platformInfo'), // 平台信息
    rememberMe: '' || storage.getItem('rememberMe'), // 记住密码
}
export default createStore({
    state,
    mutations
})