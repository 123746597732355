/**
 * Mutations业务层数据提交
 */
import storage from './../utils/storage'

export default {
    userInfo(state, userInfo) {
        state.userInfo = userInfo;
        storage.setItem('userInfo', userInfo)
    },
    regionData(state, regionData) {
        state.regionData = regionData;
        storage.setItem('regionData', regionData);
    },
    rememberMe(state, rememberMe) {
        state.rememberMe = rememberMe;
        storage.setItem('rememberMe', rememberMe);
    },
    loginToken(state, loginToken) {
        state.loginToken = loginToken;
        storage.setItem('loginToken', loginToken);
    },
    logout(state) {
        state.userInfo = '';
        storage.clearItem('regionData');
        storage.clearItem('loginToken');
        storage.clearItem('userInfo');
        storage.clearItem('avatar');
        storage.clearItem('platformInfo');
        storage.clearItem('safePass');
        storage.clearItem('currentNavIndex');
    },
    avatar(state, avatar) {
        state.avatar = avatar;
        storage.setItem('avatar', avatar);
    },
    safePass(state, safePass) {
        state.safePass = safePass;
        storage.setItem('safePass', safePass);
    },
    platformInfo(state, platformInfo) {
        state.platformInfo = platformInfo;
        storage.setItem('platformInfo', platformInfo);
    },
    currentNavIndex(state, currentNavIndex) {
        state.currentNavIndex = currentNavIndex;
        storage.setItem('currentNavIndex', currentNavIndex);
    }
}