<template>
  <router-view></router-view>
</template> 

<script>
export default {
  name: "app", 
};
</script> 

<style lang="scss">
@import "./assets/style/reset.css";
@import "./assets/style/index.scss";
@import "./assets/style/auth.scss";
 
svg {
  width: 1em; 
  height: 1em;
}

.el-dialog {
  .el-dialog__body {
    padding: 10px 20px;
  }

  .el-dialog__footer {         
    text-align: center;
  }
}

#app {
  height: 100%;
  width: 100%;
}
</style>