/**
 * 工具函数封装
 */
export default {
    // 格式化时间
    formateDate(date, rule) {
        let fmt = rule || 'yyyy-MM-dd hh:mm:ss'
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, date.getFullYear())
        }
        const o = {
            // 'y+': date.getFullYear(),
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        }
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                const val = o[k] + '';
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? val : ('00' + val).substr(val.length));
            }
        }
        return fmt;
    },
    
    // 数组item字符串变数字
    stringToNum(string) {
        // 空字符串返回空数组
        if (!string) {
            return []
        }
        return string.split(',').map(item => +item)
    },
    // 把驼峰转换成横杠连接
    toLine(value) {
        return value.replace(/(A-Z)g/, '-$1').toLowerCase()
    }
}