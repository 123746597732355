import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'
import request from './utils/request'
import storage from './utils/storage'
import api from './api'
import store from './store'
import utils from './utils/utils.js'
import './utils/rem.js'
import "lib-flexible-computer";
// import 'swiper/swiper.min.css';

const app = createApp(App);
app.config.globalProperties.$request = request;
app.config.globalProperties.$api = api;
app.config.globalProperties.$storage = storage;

router.afterEach((to,from,next) => {
      window.scrollTo(0,0);
 })

// 全局注册图标
for (let i in Icons) {
  // console.log(`el-icon-${utils.toLine(i)}`)
  app.component(`el-icon-${utils.toLine(i)}`, Icons[i])
}
app
  .use(router)
  .use(store)
  .use(ElementPlus, { size: 'small' })
  .mount('#app')
